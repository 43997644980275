import React from 'react'

import Layout from '../components/layout'
import Parser from '../components/parser'

const IndexPage = () => (
  <Layout>
    <Parser />
  </Layout>
)

export default IndexPage
