import React, { useState } from 'react'
import Papa from 'papaparse'
import { CSVDownload } from 'react-csv'

const Parser = () => {
  const [csv, setCsv] = useState(null)

  const titles = [
    'Dr\\.med\\.',
    'Dr\\. med\\.',
    'Professor Dr\\.',
    'Prof\\. Dr\\.',
    'Dr\\.',
    'Professor',
    'Prof\\.'
  ]

  const titleMatchesRegex = new RegExp(titles.join('|'))

  const handleUpload = file => {
    Papa.parse(file, {
      // encoding: 'ascii',
      complete (results) {
        const originalData = results.data

        const csvData = []

        // shift first row
        originalData.shift()

        // handle row by row
        originalData.forEach(row => {
          if (row.length > 1) {
            let name = row[2].trim()

            // Name
            let salutation = row[1]
            let title = ''
            let firstName = ''
            let lastName = ''
            const eMail = row[9] || row[10]

            const isPerson =
              salutation.includes('Herr') || salutation.includes('Frau')

            if (salutation.includes('Herr')) {
              salutation = 0
            } else if (salutation.includes('Frau')) {
              salutation = 1
            } else {
              salutation = 2
            }

            if (isPerson) {
              const titleMatches = titleMatchesRegex.exec(name)

              if (titleMatches) {
                title = titleMatches[0]

                name = name.replace(title, '').trim()
              }

              const splittedName = name.split(' ')

              lastName = splittedName.pop()
              firstName = splittedName.join(' ')
            } else {
              lastName = [name, row[3].trim(), row[4].trim()]
                .filter(i => i)
                .join(' ')
            }

            // Address
            let street = row[5].trim()
            let housenumber = ''
            const zipCode = row[7].trim()
            const city = row[8].trim()
            let country = row[6].trim() || 'DE'

            let streetRegex

            switch (country) {
            case 'F':
            case 'FR':
              streetRegex = /(^\d+(-\d+)?(\s?[\D]{1})?)\s([\-\D.\d ]+)/
              country = 'FR'
              break
            default:
              streetRegex = /(^[\-\D. ]+)\s(\d+(-\d+)?(\s?[\D]{1})?)/
            }

            const streetRegexp = new RegExp(streetRegex).exec(street)

            if (streetRegexp) {
              switch (country) {
              case 'FR':
                street = streetRegexp[4].trim()
                housenumber = streetRegexp[1].trim()
                break
              default:
                street = streetRegexp[1].trim()
                housenumber = streetRegexp[2].trim()
              }
            }

            // Build all together
            csvData.push({
              'BT-Nummer': row[0],
              Anrede: salutation,
              Titel: title,
              Vorname: firstName,
              Nachname: lastName,
              'E-Mail': eMail,
              Strasse: street,
              Hausnummer: housenumber,
              Postleitzahl: zipCode,
              Ort: city,
              Land: country,
              Mailtype: 1,
              Mailingcode: ''
            })
          }
        })

        const csv = Papa.unparse(csvData, {
          quotes: false,
          delimiter: ';',
          header: true
        })

        setCsv(csv)
      }
    })
  }

  return (
    <>
      <input type='file' onChange={e => handleUpload(e.target.files[0])} />
      {/* <pre>{csv}</pre> */}
      {csv && <CSVDownload data={csv} filename='sug.csv' />}
      Version: 1.7
    </>
  )
}

export default Parser
